type RequestOptions = RequestInit & { query?: string };

export async function parseResponse(response: Response) {
  const {data, success, error} = response.ok
    ? {
    data: await response.json(),
      success: true,
      error: {}}
    : {data: {},
      error: {message: response.statusText},
      success: false
  };

  if (!success) throw new Error(error?.message);

  // Resolve data;
  return data;
}

export function request(url: string, options: RequestOptions) {
  const {
    headers = {},
    query = null,
    method = 'GET',
    body,
    ...extraOptions
  } = options;

  const requestOptions = {
    method,
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
    body: typeof body === 'object'
      ? JSON.stringify(body)
      : body,
    ...extraOptions,
  };

  let queryString = '';
  if (query) {
    queryString = new URLSearchParams(query).toString();
    queryString = queryString && `?${queryString}`;
  }

  // debugger;

  return fetch(`${url}${queryString}`, requestOptions).then(parseResponse);
}
