import React, {Dispatch, HTMLAttributes, SetStateAction} from 'react';

export type CardPopupObject = {
  name: string;
  label: string;
  active: boolean;
  studyData: {
    'Published studies': boolean;
    'National surveys': boolean;
    'Cohorts': boolean;
    'Innovation': boolean;
    'rATA': boolean;
  },
}

export type CardPopupProps = {
  popup: CardPopupObject;
  popups: CardPopupObject[];
  setPopups: Dispatch<SetStateAction<CardPopupObject[]>>;
} & HTMLAttributes<any>;

const CardPopup = ({popup, popups, setPopups, ...props}: CardPopupProps) => {
  const popupIndex = popups.findIndex((p) => p.name === popup.name);
  const studyDataCount = Object.keys(popup.studyData).filter(studyDataType => popup.studyData[studyDataType]).length;

  return (
    <div
      style={{display: popup.active ? 'block' : 'none'}}
      data-feature-id={popup.name}
      className="map__popup"
    >
      <div className="map__popup-top">
        <header className="map__popup-header">
          <h2 className="map__popup-label">{popup.label}</h2>
          <p className="map__popup-description">{popup.label} has {studyDataCount} {studyDataCount === 1 ? 'type' : 'types'} of AT research:</p>
        </header>
        <button
          className="map__popup-close"
          onClick={() => {
            popups[popupIndex].active = false;
            setPopups([...popups]);
          }}
          aria-label="Close popup"
        >
          <svg width="45" height="45" viewBox="0 0 45 45" fill="none"
               xmlns="http://www.w3.org/2000/svg" role="presentation">
            <circle cx="22.5" cy="22.5" r="22" fill="white" stroke="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M22.5015 24.2678L30.4565 32.2227L32.2242 30.455L24.2693 22.5L32.2242 14.5451L30.4565 12.7773L22.5015 20.7323L14.5466 12.7773L12.7788 14.5451L20.7338 22.5L12.7788 30.455L14.5466 32.2227L22.5015 24.2678Z"
                  fill="currentColor"/>
          </svg>
        </button>
      </div>
      <div className="map__popup-data">
        {Object.keys(popup.studyData).map((studyType, i) => {
          return (
            <div className="map__popup-data-type" key={i}>
              <span
                className={`map__popup-data-type-box map__popup-data-type-box--${popup.studyData[studyType] ? 'active' : 'inactive'}`}>
                {popup.studyData[studyType] &&
                  <svg width="29" height="20" viewBox="0 0 29 20" fill="none"
                       xmlns="http://www.w3.org/2000/svg" role="presentation">
                    <path
                      d="M1 10.5L8.79289 18.2929C9.18342 18.6834 9.81658 18.6834 10.2071 18.2929L27.5 1"
                      stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
                  </svg>
                }
              </span>
              {studyType}
            </div>
          );
        })}
      </div>
      <div className="map__popup-bottom">
        <a className="map__popup-button" href={`by-country.html?country=${popup.name}`}>Go to {popup.label} page</a>
      </div>
    </div>
  );
}

export default CardPopup;
