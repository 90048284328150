import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, ZoomControl } from 'react-leaflet';
import { Map } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { request } from '../utils';
import countries110m from '../../data/countries-110m.json';
import MapFilters, { FilterObject } from "./MapFilters";
import VectorTileLayer from './VectorTileLayer';
import CardPopup, { CardPopupObject } from "./CardPopup";

export type StudyDataTypes = {
  'Published studies': 'x' | '';
  'National surveys': 'x' | '';
  'Cohorts': 'x' | '';
  'rATA': 'x' | 'x (national representative sample)' | 'x (non-representative)' | '';
};

export type StudyDataRecord = {
  Countries: string;
} & StudyDataTypes;

const studyDataTypes = [
  { name: 'rATA', label: 'rATA' },
  { name: 'Published studies', label: 'Published Studies' },
  { name: 'National surveys', label: 'National Surveys' },
  { name: 'Cohorts', label: 'Cohort Studies' },
];

const getStudyData = async () => {
  return request('https://opensheet.elk.sh/1UASpyY5jUr3ba_l0uBlC-h8D3dACMXj0Nk66IBId8hw/map', {});
}

const getCountriesTopoJson = async () => {
  const studyData = await getStudyData();
  const geometries = countries110m.objects.countries.geometries.map((geometry) => {
    const country = geometry.properties.NAME;
    const countryStudyData = studyData.filter((datum: StudyDataRecord) => datum.Countries === country).pop();

    return {
      ...geometry,
      properties: {
        ...geometry.properties,
        countryStudyData,
      },
    }
  });

  return geometries;
}

const MapLoading = () => {
  return (
    <>
      <div className="map__loading">
        <span>Loading...</span>
      </div>
    </>
  )
}

const LeafletMap = () => {
  const featureIdKey = 'NAME';
  const [map, setMap] = useState<Map | null>(null);
  const [mapData, setMapData] = useState({ studyData: [], countriesTopoJson: {} });
  const [loading, setLoading] = useState({ studyData: true, countriesTopoJson: true });
  const [filters, setFilters] = useState<FilterObject[]>([]);
  const [popups, setPopups] = useState<CardPopupObject[]>([]);

  const filterMapCountries = (instance) => {
    const selectedFilters = filters.filter((filter) => filter.active).map(filter => filter.name.toLowerCase());
    // Filter study data & set style for countries.
    Object.keys(mapData.countriesTopoJson).forEach((countryIndex) => {
      const country = mapData.countriesTopoJson[countryIndex];
      const studyData = mapData.studyData.filter((record: StudyDataRecord) => record.Countries === country.properties?.[featureIdKey]).pop();

      let countryIncludesSelectedFilters = selectedFilters.length === 0;
      if (studyData) {
        countryIncludesSelectedFilters = true;
        const availableCountryData = Object.keys(studyData)
          .filter(studyType => studyData?.[studyType] === 'x' || studyData?.[studyType] !== '')
          .map(studyType => studyType.toLowerCase());


        selectedFilters.every((filter) => {
          countryIncludesSelectedFilters = availableCountryData.includes(filter);
          return countryIncludesSelectedFilters;
        });
      }

      instance.setFeatureStyle(country.properties?.[featureIdKey], {
        stroke: true,
        fill: true,
        weight: 1,
        color: '#FFF',
        fillColor: countryIncludesSelectedFilters ? '#50ACC8' : '#B1B1B1',
        fillOpacity: 1,
      });
    });
  }

  useEffect(() => {
    setFilters(Object.keys(studyDataTypes).map((i) => {
      const filter = studyDataTypes[i];
      return {
        name: filter.name,
        label: filter.label,
        active: false,
      }
    }));

    (async () => {
      setMapData({ ...mapData, studyData: await getStudyData() });
      setLoading({ ...loading, studyData: false });
    })()
  }, []);

  useEffect(() => {
    if (!loading.studyData && loading.countriesTopoJson) {
      (async () => {
        const countriesTopoJson = await getCountriesTopoJson();
        setMapData({ ...mapData, countriesTopoJson });
        setLoading({ ...loading, countriesTopoJson: false });
      })()
    }

    if (!loading.countriesTopoJson) {
      setPopups(Object.keys(mapData.countriesTopoJson).map((countryIndex): CardPopupObject => {
        const data = {} as CardPopupObject['studyData'];
        studyDataTypes.forEach((studyType) => {
          data[studyType.label] = false;
        });

        const country = mapData.countriesTopoJson[countryIndex];
        const studyData = mapData.studyData.filter((record: StudyDataRecord) => record.Countries === country.properties?.[featureIdKey]).pop();

        if (studyData) {
          const availableCountryData = Object.keys(studyData)
            .filter(studyType => studyType !== 'Countries' && (studyData?.[studyType] === 'x' || studyData?.[studyType] !== ''))
            .map(studyType => studyType.toLowerCase());

          studyDataTypes.forEach((studyType) => {
            data[studyType.label] = availableCountryData.includes(studyType.name.toLowerCase());
          });
        }

        return {
          name: country.properties?.[featureIdKey],
          label: country.properties?.[featureIdKey],
          active: false,
          studyData: data,
        };
      }));
    }
  }, [loading]);

  if (loading.studyData) {
    return <MapLoading />
  }

  return (
    <>
      <div className="map__leaflet w-full-extended">
        <MapContainer
          ref={setMap}
          center={[51.505, -0.09]}
          zoom={3}
          minZoom={3}
          maxZoom={5}
          zoomControl={false}
          scrollWheelZoom={false}
          maxBounds={[[-180, -Infinity], [180, Infinity]]}
          dragging={!L.Browser.mobile}
          tap={!L.Browser.mobile}
        >
          {loading.countriesTopoJson
            ? <TileLayer
              attribution={loading.countriesTopoJson ? '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' : ''}
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            :
            <VectorTileLayer
              data={countries110m}
              featureIdKey={featureIdKey}
              onUpdate={filterMapCountries}
              vectorTileLayerStyles={{
                countries: () => {
                  return {
                    stroke: true,
                    fill: true,
                    weight: 1,
                    color: '#FFF',
                    fillColor: '#50ACC8',
                    fillOpacity: 1,
                  };
                }
              }}
              eventHandlers={{
                click: (e) => {
                  const { sourceTarget, latlng } = e;
                  const { properties, _path: path } = sourceTarget;
                  const featureId = path.getAttribute('data-feature-id') || false;

                  if (map) {
                    const clickedPathMatchesPopup = properties?.[featureIdKey] === featureId;

                    // Reset popups.
                    popups.forEach((popup, i) => {
                      popups[i].active = false;
                    });

                    map.panTo(latlng, { animate: true });

                    // Activate selected popup.
                    if (clickedPathMatchesPopup) {
                      const popupIndex = popups.findIndex((p) => p?.name === featureId);
                      const popup = popups[popupIndex] || false;

                      if (popup) {
                        popup.active = !popup.active;
                        map.on('moveend', () => setPopups([...popups]));
                      }
                    }
                  }
                },
              }} />
          }

          {popups.length > 0 &&
            <div className={`map__popups ${popups.filter(p => p.active).length > 0 ? 'map__popups--visible' : ''}`}>
              {popups.map((popup, i) => {
                return <CardPopup key={i} popup={popup} popups={popups} setPopups={setPopups} />
              })}
            </div>
          }

          <ZoomControl position="bottomleft" />
        </MapContainer>
      </div>

      {!loading.studyData &&
        <MapFilters
          className="map__filters"
          filters={filters}
          onUpdate={setFilters}
        />
      }
    </>
  )
}

export default LeafletMap;
