import React, {
  AllHTMLAttributes,
  Dispatch,
  HTMLAttributes,
  SetStateAction,
  useEffect,
  useState
} from 'react';
import { Filter, useFocusVisible } from 'react-aria';

export type MapFiltersProps = {
  filters: FilterObject[];
  onUpdate: Dispatch<SetStateAction<FilterObject[]>>
} & AllHTMLAttributes<any>;

export type FilterObject = {
  name: string;
  label: string;
  active: boolean;
}

export type FilterProps = {
  label: string;
  checked: boolean;
  index: number;
  onUpdate: (key: number, checked: boolean) => void;
} & HTMLAttributes<any>;

const Filter = ({ label, checked: checkedDefault = false, onUpdate, index, ...props }: FilterProps) => {
  const [checked, setChecked] = useState(checkedDefault);
  const [focus, setFocussed] = useState(false);
  const { isFocusVisible } = useFocusVisible({});
  const handleFocus = () => setFocussed(isFocusVisible && !focus);
  const idString = label.replace(/\s/g, '').toLowerCase();

  useEffect(() => {
    onUpdate(index, checked);
  }, [checked]);

  return (
    <label {...props} className={`map__filter ${props.className || ''} ${focus && 'map__filter--focussed' || ''}`}>
      <input
        id={idString}
        type="checkbox"
        className="map__filter-input"
        checked={checked}
        onChange={() => setChecked(!checked)}
        onFocus={handleFocus}
        onBlur={handleFocus}
      />
      <span className={`map__filter-checkbox ${checked && 'map__filter-checkbox--active' || ''}`} role="presentation">
        <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 4.85714L6.09091 10L15 1" stroke="white" strokeWidth="2" />
        </svg>
      </span>

      <span className="map__filter-label">{label}</span>
    </label>
  );
}

const checkMapFiltered = (filters: any) => {
  let activeFilter = false;
  let resetButton: HTMLButtonElement = document.getElementById("js-clear-filters");


  // Activate or deactivate the reset button
  filters.forEach(filter => {
    if (!filter.active) {
      if (!activeFilter) {
        sessionStorage.removeItem("mapfiltered");
        resetButton.disabled = true;
      }
    } else {
      activeFilter = true
      sessionStorage.setItem("mapfiltered", "true");
      resetButton.disabled = false;
    }
  });
}

const resetMap = (filters: any) => {
  console.log("reset");
  let activeFilters = [];

  filters.forEach(filter => {
    if (filter.active) {
      let filterName = filter.label;
      let reducedFilterName = filterName.replace(/\s/g, '').toLowerCase();
      let checkedFilter = document.getElementById(reducedFilterName);
      checkedFilter?.click()
    }
  });



  // if (sessionStorage.getItem("mapfiltered") === null) {
  //   console.log("Map not filtered");
  //   return
  // } else {
  //   let clickEvent = new Event('click');
  //   filters.forEach((activeFilter: FilterProps) => {

  //     console.log(activeFilter.checked)
  //   })

  //   checkMapFiltered(filters);
  // }
}

const MapFilters = ({
  filters = [],
  onUpdate,
  ...props
}: MapFiltersProps) => {
  const updateFilter = (index: number, active: boolean) => {
    const updatedFilters = [...filters];
    updatedFilters[index].active = active;
    onUpdate(updatedFilters);
    checkMapFiltered(updatedFilters);
  }

  return (
    <div className="map__filters" {...props}>
      <div className="map__filters-label">Filter by research type:</div>
      <div className="map__filters-keys">
        {filters.map((filter, i) => (
          <Filter
            key={i}
            index={i}
            label={filter.label}
            checked={filter.active}
            onUpdate={updateFilter}
          />
        ))}
        <button onClick={() => {
          resetMap([...filters]);
        }}
          id="js-clear-filters"
          className="map__filters-clear">
          Clear Filters
        </button>
      </div>
    </div>
  );
};

export default MapFilters;
