import L, { VectorGrid } from 'leaflet';
import 'leaflet.vectorgrid/dist/Leaflet.VectorGrid';
import {
  createElementObject,
  createTileLayerComponent,
  extendContext,
  LayerProps,
  LeafletContextInterface
} from '@react-leaflet/core';
import * as geojson from 'geojson';

type VectorTileLayer = L.VectorGrid.Slicer;

type VectorTileLayerProps = {
  data: geojson.GeoJSON;
  vectorTileLayerStyles?: {};
  featureIdKey?: string | number;
  onUpdate?: (instance: VectorTileLayer, props: VectorTileLayerProps, prevProps: VectorTileLayerProps) => void;
  onLoad?: (instance: VectorGrid.Slicer, context: LeafletContextInterface, props: VectorTileLayerProps) => void;
} & LayerProps;

function createVectorTileLayer(props: VectorTileLayerProps, context: LeafletContextInterface) {
  const {data: json, vectorTileLayerStyles = {}, featureIdKey, onLoad} = props;

  const vectorGrid = L.vectorGrid.slicer(json, {
    rendererFactory: L.svg.tile,
    interactive: true,
    vectorTileLayerStyles,
    getFeatureId: f => featureIdKey ?
      f.properties?.[featureIdKey] || null :
      null,
  }).on('load', (e) => {
    const {_vectorTiles: vectorTiles} = e.sourceTarget;
    Object.keys(vectorTiles).forEach((tileKey) => {
      const {_features: features} = vectorTiles[tileKey];
      Object.keys(features).forEach((featureKey) => {
        const {feature} = features[featureKey];
        const {_path: path} = feature;
        path.setAttribute('data-feature-id', featureIdKey ? feature.properties?.[featureIdKey] : featureKey)
      })
    });
  });

  const elementObject = createElementObject(vectorGrid, extendContext(context, {}));

  if (onLoad) {
    onLoad(elementObject.instance, elementObject.context, props);
  }

  return elementObject;
}

function updateVectorTileLayer(instance: VectorTileLayer, props: VectorTileLayerProps, prevProps: VectorTileLayerProps) {
  if (props.onUpdate) {
    props.onUpdate(instance, props, prevProps);
  }

  return instance;
}

const VectorTileLayer = createTileLayerComponent(createVectorTileLayer, updateVectorTileLayer);

export default VectorTileLayer;
