import React from 'react';
import {createRoot} from 'react-dom/client';
import LeafletMap from "./LeafletMap";

const initializeMaps = () => {
  const maps = document.querySelectorAll('.map__leaflet-wrapper');

  maps.forEach((map) => {
    const root = createRoot(map);
    root.render(<LeafletMap />);
  });
}

initializeMaps();
